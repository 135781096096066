const isNaN = num=> num !== num;

import jQuery from 'jquery';
const $ = jQuery;

class MapsControl {
  getDiv() {
    return this.controlDiv.get(0);
  }
}
class FullScreenControl extends MapsControl {
  constructor(map, $map){
    super();
    this.controlDiv = $("<div class=\"map-control\"></div>");
    this.controlUI = $("<div title=\"Toggle fullscreen\"><i class=\"icon-fullscreen\"></i></div>");
    this.controlDiv.append(this.controlUI);
    google.maps.event.addListener(map, 'resize', () => {
      console.log("Resize!", this.center);
      setTimeout(() => {
        map.setCenter(this.center);
      }, 100);
    });
    google.maps.event.addDomListener(this.controlUI.get(0), 'click', () => {
      $map.toggleClass('fullscreen');
      this.center = map.getCenter();
      google.maps.event.trigger(map, "resize");
    });
  }
}
class GotoControl extends MapsControl {
  constructor(name, map, center, bounds) {
    super();
    this.controlDiv = $("<div class=\"map-control\"></div>");
    this.controlUI = $(`<div title=\"Go to ${name}\">${name}</div>`);
    this.controlDiv.append(this.controlUI);
    google.maps.event.addDomListener(this.controlUI.get(0), 'click', function() {
      if (map.getZoom() < 16) { map.setZoom(16); }
      if (center != null) { map.setCenter(center); }
      setTimeout(function() {
        if (bounds != null) {
          map.panToBounds(bounds);
        }
      }, 100);
    });
  }
}
class ToggleControl extends MapsControl {
  constructor(name, map, activated = false){
    super();
    this.controlDiv = $("<div class=\"map-control\"></div>");
    this.controlUI = $(`<div title=\"Toggle ${name}\">${name}</div>`);
    this.activated = activated;
    this.activate = function() {};
    this.deactivate = function() {};
    this.controlDiv.append(this.controlUI);
    google.maps.event.addDomListener(this.controlUI.get(0), 'click', () => {
      this.activated = !this.activated;
      return this.updateState();
    });
  }
  updateState() {
    if (this.activated) {
      if (!this.controlUI.hasClass('active')) { this.controlUI.addClass('active'); }
      return this.activate();
    } else {
      this.controlUI.removeClass('active');
      return this.deactivate();
    }
  }
  setActive(active){
    if (this.activated !== active) {
      this.activated = active;
      return this.updateState();
    }
  }
}

const initRegionMap = function($map){
  const mapOptions = {
    center: new google.maps.LatLng(52.2129919,5.2793703),
    zoom: 8
  };
  if ($map.data('zoom')) { mapOptions.zoom = $map.data('zoom'); }
  const map = new google.maps.Map($map.get(0), mapOptions);
  const fullscreenControl = new FullScreenControl(map, $map);
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(fullscreenControl.getDiv());
  let north = parseFloat($('#edition_north').val());
  let west  = parseFloat($('#edition_west').val());
  let south = parseFloat($('#edition_south').val());
  let east  = parseFloat($('#edition_east').val());
  const initRegion = function() {
    let southWest = new google.maps.LatLng(south, west);
    let northEast = new google.maps.LatLng(north, east);
    let bounds = new google.maps.LatLngBounds(southWest, northEast);
    const rectangle = new google.maps.Rectangle({
      bounds,
      editable: true
    });
    rectangle.setMap(map);
    map.panTo(new google.maps.LatLng((north + south)/2, (west + east)/2));
    map.setZoom(15);
    map.panToBounds(bounds);
    google.maps.event.addListener(rectangle, 'bounds_changed', function() {
      console.log('Bounds changed.');
      bounds = rectangle.getBounds();
      southWest = bounds.getSouthWest();
      northEast = bounds.getNorthEast();
      north = northEast.lat();
      south = southWest.lat();
      east = northEast.lng();
      west = southWest.lng();
      $('#edition_west').val(west);
      $('#edition_east').val(east);
      $('#edition_north').val(north);
      $('#edition_south').val(south);
    });
  };
  if (isNaN(north) || isNaN(west) || isNaN(south) || isNaN(east)) {
    console.log("Coords incomplete");
    const coordInfoWindow = new google.maps.InfoWindow();
    const positionContainer = $('<div><button type="button" class="btn" data-lat="north" data-lng="west">Set top left</button><button type="button" class="btn" data-lat="north" data-lng="east">Set top right</button><br><button type="button" class="btn" data-lat="south" data-lng="west">Set bottom left</button><button type="button" class="btn" data-lat="south" data-lng="east">Set bottom right</button></div>');
    let latLng = null;
    coordInfoWindow.setContent(positionContainer.get(0));
    const clickMap = function(event){
      console.log("Bla");
      ({ latLng } = event);
      coordInfoWindow.setPosition(event.latLng);
      coordInfoWindow.open(map);
    };
    positionContainer.on('click', 'button', function(e){
      if ($(this).data('lng') === 'west') {
        west = latLng.lng();
        $('#edition_west').val(west);
      }
      if ($(this).data('lng') === 'east') {
        east = latLng.lng();
        $('#edition_east').val(east);
      }
      if ($(this).data('lat') === 'north') {
        north = latLng.lat();
        $('#edition_north').val(north);
      }
      if ($(this).data('lat') === 'south') {
        south = latLng.lat();
        $('#edition_south').val(south);
      }
      if (!isNaN(north) && !isNaN(west) && !isNaN(south) && !isNaN(east)) {
        google.maps.event.removeListener(map, 'click', clickMap);
        initRegion();
      }
    });
    google.maps.event.addListener(map, 'click', clickMap);
  } else {
    initRegion();
  }
};

class Polygon {
  constructor(coordinatesField, fillColorField, fillOpacityField, strokeColorField, strokeOpacityField, strokeWeightField){
    this.coordinatesField   = $(coordinatesField);
    this.fillColorField     = $(fillColorField);
    this.fillOpacityField   = $(fillOpacityField);
    this.strokeColorField   = $(strokeColorField);
    this.strokeOpacityField = $(strokeOpacityField);
    this.strokeWeightField  = $(strokeWeightField);
    this.rawCoordinates = this.coordinatesField.val().split(",").map(coord=> parseFloat(coord)).reduce(function(result, item, index){
      if ((index % 2) === 0) {
        result.push([item]);
        return result;
      } else {
        result[result.length-1].push(item);
        return result;
      }
    }
    ,[]);
    this.coordinates = this.rawCoordinates.map(item=> new google.maps.LatLng(item[0], item[1]));
    this.mvcCoordinates = new google.maps.MVCArray(this.coordinates);
    google.maps.event.addListener(this.mvcCoordinates, 'insert_at', e=> {
      this.saveCoords();
    });
    google.maps.event.addListener(this.mvcCoordinates, 'remove_at', e=> {
      this.saveCoords();
    });
    google.maps.event.addListener(this.mvcCoordinates, 'set_at', e=> {
      this.saveCoords();
    });
    this.readNonCoordFields();
    const changeNonCoordField = e=> {
      this.readNonCoordFields();
      this.initPolygon();
    };
    this.fillColorField.on('change', changeNonCoordField);
    this.fillOpacityField.on('change', changeNonCoordField);
    this.strokeColorField.on('change', changeNonCoordField);
    this.strokeOpacityField.on('change', changeNonCoordField);
    this.strokeWeightField.on('change', changeNonCoordField);
    this.initPolygon();
  }
  saveCoords() {
    const coordinates = [];
    this.mvcCoordinates.forEach(function(item){
      coordinates.push(item.lat());
      coordinates.push(item.lng());
    });
    this.coordinatesField.val(coordinates.join(","));
  }
  readNonCoordFields() {
    this.fillColor = this.fillColorField.val();
    this.fillOpacity = parseFloat(this.fillOpacityField.val());
    this.strokeColor = this.strokeColorField.val();
    this.strokeOpacity = parseFloat(this.strokeOpacityField.val());
    this.strokeWeight = parseFloat(this.strokeWeightField.val());
  }
  destroyPolygon() {
    this.polygon.setMap(null);
    google.maps.event.clearListeners(this.polygon, 'dblclick');
  }
  initPolygon() {
    if (this.polygon) { this.destroyPolygon(); }
    this.polygon = new google.maps.Polygon({
      editable: true,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      strokeColor: this.strokeColor,
      strokeOpacity: this.strokeOpacity,
      strokeWeight: this.strokeWeight,
      zIndex: 2000,
      paths: [this.mvcCoordinates]});
    google.maps.event.addListener(this.polygon, 'dblclick', e=> {
      if (e.vertex != null) {
        if (this.mvcCoordinates.getLength() > 3) {
          this.mvcCoordinates.removeAt(e.vertex);
        }
      }
    });
    if (this.map != null) {
      this.polygon.setMap(this.map.map);
    }
  }
  setMap(map){
    this.map = map;
    this.polygon.setMap(map.map);
  }
}

class Polyline {
  constructor(coordinatesField, strokeColorField, strokeOpacityField, strokeWeightField){
    this.coordinatesField   = $(coordinatesField);
    this.strokeColorField   = $(strokeColorField);
    this.strokeOpacityField = $(strokeOpacityField);
    this.strokeWeightField  = $(strokeWeightField);
    this.rawCoordinates = this.coordinatesField.val().split(",").map(coord=> parseFloat(coord)).reduce(function(result, item, index){
      if ((index % 2) === 0) {
        result.push([item]);
        return result;
      } else {
        result[result.length-1].push(item);
        return result;
      }
    }
    ,[]);
    this.coordinates = this.rawCoordinates.map(item=> new google.maps.LatLng(item[0], item[1]));
    this.mvcCoordinates = new google.maps.MVCArray(this.coordinates);
    google.maps.event.addListener(this.mvcCoordinates, 'insert_at', e=> {
      this.saveCoords();
    });
    google.maps.event.addListener(this.mvcCoordinates, 'remove_at', e=> {
      this.saveCoords();
    });
    google.maps.event.addListener(this.mvcCoordinates, 'set_at', e=> {
      this.saveCoords();
    });
    this.readNonCoordFields();
    const changeNonCoordField = e=> {
      this.readNonCoordFields();
      this.initPolygon();
    };
    this.strokeColorField.on('change', changeNonCoordField);
    this.strokeOpacityField.on('change', changeNonCoordField);
    this.strokeWeightField.on('change', changeNonCoordField);
    this.initPolyline();
  }
  saveCoords() {
    // TODO: Save stuff
    const coordinates = [];
    this.mvcCoordinates.forEach(function(item){
      coordinates.push(item.lat());
      coordinates.push(item.lng());
    });
    this.coordinatesField.val(coordinates.join(","));
  }
  readNonCoordFields() {
    this.strokeColor = this.strokeColorField.val();
    this.strokeOpacity = parseFloat(this.strokeOpacityField.val());
    this.strokeWeight = parseFloat(this.strokeWeightField.val());
  }
  destroyPolyline() {
    this.polyline.setMap(null);
    google.maps.event.clearListeners(this.polyline, 'dblclick');
  }
  initPolyline() {
    if (this.polyline) { this.destroyPolygon(); }
    this.polyline = new google.maps.Polyline({
      editable: true,
      strokeColor: this.strokeColor,
      strokeOpacity: this.strokeOpacity,
      strokeWeight: this.strokeWeight,
      zIndex: 2000,
      path: this.mvcCoordinates
    });
    google.maps.event.addListener(this.polyline, 'dblclick', e=> {
      if (e.vertex != null) {
        if (this.mvcCoordinates.getLength() > 2) {
          this.mvcCoordinates.removeAt(e.vertex);
        }
      }
    });
    if (this.map != null) {
      this.polyline.setMap(this.map.map);
    }
  }
  setMap(map){
    this.map = map;
    this.polyline.setMap(map.map);
  }
}


class Marker {
  constructor(latitudeField, longitudeField){
    this.latitudeField  = $(latitudeField);
    this.longitudeField = $(longitudeField);
    this.coordinate = new google.maps.LatLng(this.latitudeField.val(), this.longitudeField.val());
    this.initMarker();
  }
  saveCoords() {
    console.log(this.marker.position.lat(), this.marker.position.lng());
    this.coordinate = this.marker.position;
    this.latitudeField.val(this.coordinate.lat());
    this.longitudeField.val(this.coordinate.lng());
  }
  destroyMarker() {
    this.marker.setMap(null);
    google.maps.event.clearListeners(this.marker, 'dblclick');
  }
  initMarker() {
    if (this.marker) { this.destroyMarker(); }
    this.marker = new google.maps.Marker({
      position: this.coordinate,
      editable: true,
      draggable: true,
      zIndex: 2000
    });
    google.maps.event.addListener(this.marker, 'position_changed', e=> {
      console.log("Change!");
      this.saveCoords();
    });
    if (this.map != null) {
      this.marker.setMap(this.map.map);
    }
  }
  setMap(map){
    this.map = map;
    this.marker.setMap(map.map);
  }
}


class MapItem {
  constructor(attributes, baseIndex){
    if (baseIndex == null) { baseIndex = 1000; }
    this.attributes = attributes;
    this.baseIndex = baseIndex;
    this.name = attributes.name;
    this.type = attributes.type;
    this.coords = attributes.coords;
    this.coordinates = this.coords.reduce(function(result, item, index){
      if ((index % 2) === 0) {
        result.push([item]);
        return result;
      } else {
        result[result.length-1].push(item);
        return result;
      }
    }
    ,[]);
    if (this.type === "Polygon") {
      this.coordinates = this.coordinates.map(item=> new google.maps.LatLng(item[0], item[1]));
      this.shape = new google.maps.Polygon({
        clickable: false,
        fillColor: attributes.fill_color,
        fillOpacity: attributes.fill_opacity,
        strokeColor: attributes.stroke_color,
        strokeOpacity: attributes.stroke_opacity,
        strokeWeight: attributes.stroke_weight,
        zIndex: this.baseIndex + attributes.position,
        paths: [this.coordinates]});
    } else if (this.type === "Polyline") {
      this.coordinates = this.coordinates.map(item=> new google.maps.LatLng(item[0], item[1]));
      this.shape = new google.maps.Polyline({
        clickable: false,
        strokeColor: attributes.stroke_color,
        strokeOpacity: attributes.stroke_opacity,
        strokeWeight: attributes.stroke_weight,
        zIndex: this.baseIndex + attributes.position,
        path: this.coordinates
      });
    }
  }

  setMap(map){
    this.map = map;
    this.shape && this.shape.setMap(map.map);
  }
  setVisible(visible){
    this.shape && this.shape.setVisible(visible);
  }
}

class DefaultMap {
  constructor($map){
      this.blackoutRects = null;
    this.$map = $map;
    const rawBounds = (this.rawBounds = $map.data('bounds'));
    const southWest = new google.maps.LatLng(rawBounds.south, rawBounds.west);
    const northEast = new google.maps.LatLng(rawBounds.north, rawBounds.east);
    const center = new google.maps.LatLng((rawBounds.north + rawBounds.south)/2, (rawBounds.west + rawBounds.east)/2);
    const bounds = new google.maps.LatLngBounds(southWest, northEast);
    const mapOptions = {
      center,
      zoom: 16
    };
    if ($map.data('zoom')) { mapOptions.zoom = $map.data('zoom'); }
    this.map = new google.maps.Map($map.get(0), mapOptions);
    this.map.panToBounds(bounds);
    this.homeControl = new GotoControl('Home', this.map, center, bounds);
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.homeControl.getDiv());
    this.fullscreenControl = new FullScreenControl(this.map, this.$map);
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.fullscreenControl.getDiv());
    this.initBlackoutRects();
    this.addStages();
    this.showStages();
    this.initMapItems();
    this.initMapMarkers();
  }
  initMapMarkers() {
    this.mapMarkers = this.$map.data('markers');
    if (this.mapMarkers == null) { return; }
    this.markers = [];
    this.mapMarkers.forEach(mapMarker=> {
      const coordinate = new google.maps.LatLng(mapMarker.latitude, mapMarker.longitude);
      console.log(coordinate);
      const marker = new google.maps.Marker({
        position: coordinate,
        icon: mapMarker.image,
        zIndex: 2000
      });
      marker.setMap(this.map);
      this.markers.push(marker);
    });
    window.markers = this.markers;
  }
  initMapItems() {
    this.objectPosition = this.$map.data('position');
    this.mapItems = this.$map.data('map-items');
    this.upperMapItems = [];
    if (this.mapItems == null) {
      this.mapItems = [];
      return;
    }
    if (this.objectPosition != null) {
      this.upperMapItems = this.mapItems.filter(mapItem=> {
        return mapItem.position > this.objectPosition;
      });
      this.mapItems = this.mapItems.filter(mapItem=> {
        return mapItem.position < this.objectPosition;
      });
    }
    this.mapItems = this.mapItems.map(mapItem=> new MapItem(mapItem));
    this.upperMapItems = this.upperMapItems.map(mapItem=> new MapItem(mapItem, 3000));
    this.mapItems.forEach(mapItem=> {
      mapItem.setMap(this);
    });
    this.upperMapItems.forEach(mapItem=> {
      mapItem.setVisible(false);
      mapItem.setMap(this);
    });
    if (this.upperMapItems.length > 0) {
      this.upperItemsControl = new ToggleControl("Layers above", this);
      this.upperItemsControl.activate = () => {
        this.upperMapItems.forEach(mapItem=> mapItem.setVisible(true));
      };
      this.upperItemsControl.deactivate = () => {
        this.upperMapItems.forEach(mapItem=> mapItem.setVisible(false));
      };
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.upperItemsControl.getDiv());
      this.upperItemsControl.setActive(false);
      this.lowerItemsControl = new ToggleControl("Layers below", this);
      this.lowerItemsControl.activate = () => {
        this.mapItems.forEach(mapItem=> mapItem.setVisible(true));
      };
      this.lowerItemsControl.deactivate = () => {
        this.mapItems.forEach(mapItem=> mapItem.setVisible(false));
      };
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.lowerItemsControl.getDiv());
      this.lowerItemsControl.setActive(true);
    } else if (this.mapItems.length > 0) {
      this.lowerItemsControl = new ToggleControl("Shapes", this);
      this.lowerItemsControl.activate = () => {
        this.mapItems.forEach(mapItem=> mapItem.setVisible(true));
      };
      this.lowerItemsControl.deactivate = () => {
        this.mapItems.forEach(mapItem=> mapItem.setVisible(false));
      };
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.lowerItemsControl.getDiv());
      this.lowerItemsControl.setActive(true);
    }
  }

  initBlackoutRects() {
    const left = new google.maps.LatLngBounds(new google.maps.LatLng(-90, -180), new google.maps.LatLng(this.rawBounds.north, this.rawBounds.west));
    const right = new google.maps.LatLngBounds(new google.maps.LatLng(this.rawBounds.south, this.rawBounds.east), new google.maps.LatLng(90, 180));
    const top = new google.maps.LatLngBounds(new google.maps.LatLng(this.rawBounds.north, -180), new google.maps.LatLng(90, this.rawBounds.east));
    const bottom = new google.maps.LatLngBounds(new google.maps.LatLng(-90, this.rawBounds.west), new google.maps.LatLng(this.rawBounds.south, 180));
    this.blackoutRects = [left, right, top, bottom].map(bounds=>
      new google.maps.Rectangle({
        bounds,
        fillColor: '#000000',
        fillOpacity: 0.5,
        strokeWeight: 0
      })
    );
    this.blackoutControl = new ToggleControl("Blackout", this);
    this.blackoutControl.activate = () => {
      this.showBlackoutRects();
    };
    this.blackoutControl.deactivate = () => {
      this.hideBlackoutRects();
    };
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.blackoutControl.getDiv());
    this.blackoutControl.setActive(true);
  }
  showBlackoutRects() {
    if (this.blackoutRects != null) {
      this.blackoutRects.forEach(rect=> {
        rect.setMap(this.map);
      });
    }
  }
  hideBlackoutRects() {
    if (this.blackoutRects != null) {
      this.blackoutRects.forEach(rect=> rect.setMap(null));
    }
  }
  addMapShapes() {}
    // TODO
  addStages() {
    if (!this.$map.data('stages')) { return; }
    const stages = this.$map.data('stages');
    if (!(stages.length > 0)) { return; }
    this.stages = stages.map(stage=> {
      if ((stage.latitude != null) && (stage.longitude != null) && (stage.radius != null)) {
        return new google.maps.Circle({
          center: new google.maps.LatLng(stage.latitude, stage.longitude),
          radius: stage.radius,
          map: this.map,
          zIndex: 2500
        });
      } else {
        return null;
      }
  }).filter(stage=> stage !== null);
    this.stagesControl = new ToggleControl("Stages", this);
    this.stagesControl.activate = () => {
      this.stages.forEach(stage=> stage.setVisible(true));
    };
    this.stagesControl.deactivate = () => {
      this.stages.forEach(stage=> stage.setVisible(false));
    };
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.stagesControl.getDiv());
  }
  showStages() {
    if (this.stages == null) { return; }
    this.stagesControl.setActive(true);
  }
  editObject(object){
    this.editingObject = object;
    this.editingObject.setMap(this);
  }
  editCircle(latField, lngField, radiusField){
    this.latField = $(latField);
    this.lngField = $(lngField);
    this.radiusField = $(radiusField);
    this.circleLat = parseFloat(this.latField.val());
    this.circleLng = parseFloat(this.lngField.val());
    this.circleRadius = parseFloat(this.radiusField.val());
    const initCircleEdit = () => {
      let updateCircle;
      this.circle = new google.maps.Circle({
        center: new google.maps.LatLng(this.circleLat, this.circleLng),
        radius: this.circleRadius,
        zIndex: 2700
      });

      this.circle.setMap(this.map);
      this.circle.setEditable(true);
      google.maps.event.addListener(this.circle, 'center_changed', event=> {
        const center = this.circle.getCenter();
        this.circleLat = center.lat();
        this.circleLng = center.lng();
        this.saveCircle();
      });
      google.maps.event.addListener(this.circle, 'radius_changed', event=> {
        this.circleRadius = this.circle.getRadius();
        this.saveCircle();
      });
      return updateCircle = () => {
        this.circle.setCenter(new google.maps.LatLng(this.circleLat, this.circleLng));
        this.circle.setRadius(this.circleRadius);
      };
    };
    if (isNaN(this.circleLat) || isNaN(this.circleLng) || isNaN(this.circleRadius)) {
      const self = this;
      const clickMap = event=> {
        console.log("X", this.map);
        const { latLng } = event;
        this.circleLat = latLng.lat();
        this.circleLng = latLng.lng();
        this.circleRadius = 10;
        // google.maps.event.removeListener @map, 'click', clickMap
        this.saveCircle();
        initCircleEdit();
      };
      google.maps.event.addListener(this.map, 'click', clickMap);
    } else {
      initCircleEdit();
    }
  }
  saveCircle() {
    this.latField.val(this.circleLat);
    this.lngField.val(this.circleLng);
    this.radiusField.val(this.circleRadius);
  }
}

jQuery(function($){
  const $map = $('#map');
  if ($map.length === 0) { return; }
  if ($map.data('map-type') === "edition-region") {
    initRegionMap($map);
  } else {
    const map = new DefaultMap($map);
    window.map = map;
    if ($map.data('type') === 'edit-stage') {
      map.editCircle('#stage_latitude', '#stage_longitude', '#stage_radius');
    } else if ($map.data('type') === 'mapitem-polygon') {
      const polygon = new Polygon('#map_item_coordinates', '#map_item_fill_color', '#map_item_fill_opacity', '#map_item_stroke_color', '#map_item_stroke_opacity', '#map_item_stroke_weight');
      map.editObject(polygon);
    } else if ($map.data('type') === 'mapitem-polyline') {
      const polyline = new Polyline('#map_item_coordinates', '#map_item_stroke_color', '#map_item_stroke_opacity', '#map_item_stroke_weight');
      map.editObject(polyline);
    } else if ($map.data('type') === 'edit-marker') {
      console.log('edit marker');
      const marker = new Marker('#map_marker_latitude', '#map_marker_longitude');
      map.editObject(marker);
    }
  }
});
